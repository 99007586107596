<template>
  <v-navigation-drawer v-if="!$root.mobile"
    v-model="dialog"
    fixed
    :right="$root.lang !='ar'"
    width="50vw"
    temporary
    style="z-index:10000"
  >
    <Add @closeClicked="doclose" @addnewapp="updatelist" />
  </v-navigation-drawer>
  <v-dialog fullscreen transition="slide-x-reverse-transition"  v-model="mobile" v-else scrollable>
    <Add ref="mobileapp" :dPreSelection="dSelected" @closeClicked="doclose" @addnewapp="updatelist"  />
  </v-dialog>
</template>

<script>
export default {
  name: "AddWrapper",
  components: { Add: () => import("./add") },
  data() {
    return {
      dialog: false,
      mobile:false,
      dSelected:null
    };
  },
  mounted() {},
  methods: {
    doclose() {
      if(this.dialog)
      {
        this.dialog = !this.dialog;
      }
      if(this.mobile)
      {
        this.mobile = !this.mobile;
      }
    },
    doOpen(d = null) {
      if(!this.dialog)
      {
        this.dialog = !this.dialog;
      }
      if(!this.mobile)
      {
        console.log("creating back event listerner")
        this.$cap.addListener("backButton", (data) => {
            console.log("hitting back in the book appointment in mobile", data);
            if(this.$refs.mobileapp)
              this.$refs.mobileapp.backHandler();
            
          });
        this.mobile = !this.mobile;
        if(d != null)
        {
          this.dSelected = d;
        }
      }
    },
    updatelist()
    {
      this.$emit("addnewapp", true)
    }
  },
};
</script>
<style scoped>
.drawer
{
  position:fixed;
  top:0;
  right: 0;
  z-index: 1000;
}
</style>