<template>
  <div>
    <v-toolbar flat style="border-radius:16px;" v-if="!this.$root.mobile" class="mb-3">
      <v-btn-toggle color="primary accent-3" tile group mandatory rounded>
        <v-btn @click="switchTab(0)">
          <v-icon>mdi-calendar-today</v-icon>
          {{ $t("APPOINTMENTS.TABS.upcoming") }}
        </v-btn>
        <v-btn @click="switchTab(1)">
          <v-icon>mdi-calendar-check</v-icon>
          {{ $t("APPOINTMENTS.TABS.previous") }}
        </v-btn>
      </v-btn-toggle>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="openAddAppointment()" elevation="0" rounded>
        <v-icon left dark class="mr-3"> mdi-plus </v-icon
        >{{ $t("MF.add") }}
      </v-btn>
    </v-toolbar>
    <div class="row customtab mx-0" v-else>
      <div :class="'col-6 tabbtn tab_first_item '+ active_tab[0]">
        <div @click="switchTab(0)"
          >{{ $t("APPOINTMENTS.TABS.upcoming") }}</div
        >
      </div>
      <div :class="'col-6 tabbtn tab_last_item '+ active_tab[1]">
        <div @click="switchTab(1)"
          >{{ $t("APPOINTMENTS.TABS.previous") }}</div
        >
      </div>
    </div>
    

    <div v-if="showupcoming">
      <transition name="fade-in-up">
        <AppointmentUpcoming :reload="reloadupcoming" :skltoload="7" />
      </transition>
    </div>
    <div v-if="showprevious" >
      <transition name="fade-in-up">
        <AppointmentPrevious />
      </transition>
    </div>
    <v-fab-transition v-if="this.$root.mobile">
      <v-btn
        color="primary"
        fab
        dark
        small
        fixed
        bottom
        right
        large
        :class="($root.tabspadding > 0) ? 'mb-25' :'mb-12'"
        @click="openAddAppointment()"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    <AddWrapper
      @addnewapp="reloadupcoming++"
      ref="add"
    ></AddWrapper>
  </div>
</template>

<script>
import AppointmentUpcoming from "./comp/appointments/upcoming.vue";
import AppointmentPrevious from "./comp/appointments/previous.vue";
import AddWrapper from "@/view/pages/patient/comp/appointments/addwrapper.vue";

export default {
  name: "appointments",
  components: {
    AppointmentUpcoming,
    AppointmentPrevious,
    AddWrapper
  },
  data() {
    return {
      showadd: false,
      showupcoming: true,
      showprevious: false,
      clickedButton: undefined,
      reloadupcoming: 0,
      active_tab:["active_tab", ""]
    };
  },
  computed: {},
  mounted() {
    this.$root.title = this.$t("MENU.appointment");
    this.$root.currentpage = this.$route.name;
  },
  destroyed() {},
  methods: {
    openAddAppointment()
    {
      this.$refs.add.doOpen();
    },
    switchTab(clicked) {
      if (clicked == 0) {
        this.showupcoming = true;
        this.showprevious = false;
        this.active_tab[0]= "active_tab";
        this.active_tab[1]= "";
      } else if (clicked == 1) {
        this.showupcoming = false;
        this.showprevious = true;
        this.active_tab[0]= "";
        this.active_tab[1]= "active_tab";
      }
    },
    checkMargin() {
      if (this.$root.mobile) {
        return "margin-top:50px !important";
      }
      return "";
    }
  }
};
</script>
<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
.v-application--wrap {
  min-height: 0px !important;
}


</style>
