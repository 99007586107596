<template>
  <v-card class="elevation-0" v-if="!this.$root.mobile">
    <v-card-title>
      {{ $t("APPOINTMENTS.TABS.previous") }}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="search"
        :label="$t('APPOINTMENTS.APP.search')"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="apps"
      :search="search"
      :loading="loadingStatus"
      :locale="this.$root.lang"
      :loading-text="$t('tLoading')"
    >
      <!-- #item.owner -->
      <template v-slot:item.doctor.doctor_id.first_name="{ item }">
        <Avatar :user="item.doctor.doctor_id"></Avatar>
      </template>
      <template v-slot:item.status="{ item }">
        <div class="d-flex align-items-center">
          <span
            v-if="item.status == 'published'"
            class="dot-blue primary ml-3"
            v-b-tooltip.hover.right="$t('APPOINTMENTS.APP.a_status.pub')"
          ></span>
          <span
            v-if="item.status == 'deleted'"
            class="dot-red danger ml-3"
            v-b-tooltip.hover.right="$t('APPOINTMENTS.APP.a_status.del')"
          ></span>
          <span
            v-if="item.status == 'draft'"
            class="dot-red danger ml-3"
            v-b-tooltip.hover.right="$t('APPOINTMENTS.APP.a_status.del')"
          ></span>
          <span
            v-if="item.status == 'pending'"
            class="dot-blue primary ml-3"
            v-b-tooltip.hover.right="$t('APPOINTMENTS.APP.a_status.pending')"
          ></span>
          <span
            v-if="item.status == 'progress'"
            class="dot-yellow warning ml-3"
            v-b-tooltip.hover.right="$t('APPOINTMENTS.APP.a_status.pro')"
          ></span>
          <span
            v-if="item.status == 'complete'"
            class="dot-green success ml-3"
            v-b-tooltip.hover.right="$t('APPOINTMENTS.APP.a_status.com')"
          ></span>
        </div>
      </template>
      <template v-slot:item.date="{ item }">
        <div class="d-flex align-items-center">
          <span class="text-dark-75 font-weight-bold font-size-sm mr-2">{{
            item.date
          }}</span>
        </div>
      </template>
      <template v-slot:item.time="{ item }">
        <div class="d-flex align-items-center">
          <span class="text-dark-75 font-weight-bold font-size-sm mr-2">{{
            (item.date_time + "Z")
              | moment("timezone", getTimeZone(), "hh:mm A")
          }}</span>
          <span class="text-dark-75 font-weight-light font-size-sm"
            >({{
              (item.date_time + "Z")
                | moment("timezone", getTimeZone(), "from", "now")
            }})</span
          >
        </div>
      </template>
      <template v-slot:item.type="{ item }">
        <i
          v-b-tooltip.hover.right="$t('APPOINTMENTS.APP.type_d.video')"
          v-if="item.type == 'video'"
          class="fas fa-video"
        ></i>
        <i
          v-b-tooltip.hover.right="$t('APPOINTMENTS.APP.type_d.clinic')"
          v-if="item.type == 'clinic'"
          class="fas fa-user-friends"
        ></i>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn
          elevation="0"
          class="mx-1 white--text"
          :key="item.id"
          rounded
          color="teal"
          :disabled="isloadingsum"
          :loading="isloadingsum"
          @click="getsummery(item.id)"
        >
          <unicon
            style="margin-right: auto;margin-left: auto;"
            name="file-check-alt"
            fill="#FFFFFF"
            height="20"
          ></unicon
          >Report
        </v-btn>
      </template>
    </v-data-table>
    <Summery
      :via="via"
      :dialog="opensummery"
      @closeClicked="opensummery = false"
    />
    <v-dialog v-model="opennosummery" max-width="290">
      <v-card>
        <v-card-title class="headline">
          Report not available
        </v-card-title>

        <v-card-text>
          There seems to be no changes made during this appointment.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="opennosummery = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
  <div class="bg-transparent" v-else>
    <div
      v-if="loadingStatus"
      class="text-center "
      style="width: 100%; margin-right: auto; margin-left: auto"
    >
      <v-skeleton-loader
        v-for="i in 5"
        :key="i"
        class="my-2"
        height="80"
        type="list-item-avatar-three-line"
      ></v-skeleton-loader>
    </div>
    <v-list v-else three-line class="bg-transparent">
      <template>
        <div v-for="item in apps" :key="item.id" class="mb-2 listitem">
          <v-list-item style="min-height:80px">
            <v-list-item-avatar size="47" color="primary">
              <v-img
                v-if="item.doctor.doctor_id.avatar != null"
                :src="item.doctor.doctor_id.avatar.data.thumbnails[0].url"
              ></v-img>
              <div
                v-else
                style="text-transform: uppercase;width:100%"
                class="white--text font-size-h4"
              >
                {{ item.doctor.doctor_id.first_name.charAt(0) }}
              </div>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <div class="listitemtitle" v-if="item.type == 'video'">
                  {{ $t("APPOINTMENTS.APP.vidwith") }}<br />
                  {{ item.doctor.doctor_id.first_name }}
                  {{ item.doctor.doctor_id.last_name }}
                </div>
                <div class="listitemtitle" v-else-if="item.type == 'clinic'">
                  {{ $t("APPOINTMENTS.APP.clinicwith") }}<br/>
                  {{ item.doctor.doctor_id.first_name }}
                  {{ item.doctor.doctor_id.last_name }}
                </div>
                <div class="listitemsubtitle">
                  {{
                    (item.date_time + "Z")
                      | moment(
                        "timezone",
                        getTimeZone(),
                        "ddd DD/MM/YYYY hh:mm A"
                      )
                  }}
                  {{
                    (item.date_time + "Z")
                      | moment("timezone", getTimeZone(), "from", "now")
                  }}
                </div>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                @click="getsummery(item.id)"
                small
                fab
                elevation="0"
                color="rgba(165, 203, 239, 0.20)"
              >
                <unicon
                  style="margin-right: auto;margin-left: auto;"
                  name="file-check-alt"
                  fill="#28a745"
                  height="20"
                ></unicon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </div>
      </template>
    </v-list>
    <Summery
      :via="via"
      :dialog="opensummery"
      @closeClicked="opensummery = false"
    />
    <v-dialog v-model="opennosummery" max-width="290">
      <v-card>
        <v-card-title class="headline">
          {{$t("APPOINTMENTS.previous.alert.title")}}
        </v-card-title>

        <v-card-text>
          {{$t("APPOINTMENTS.previous.alert.body")}}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="opennosummery = false">
            {{$t("POPUPS.cancel")}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div
      v-if="apps.length == 0 && !loadingStatus"
      class="text-muted font-size-h4"
      style="text-align: center;
    margin-top: 40px;"
    >
      {{ $t("APPOINTMENTS.APP.noappsfound") }}
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Avatar from "@/view/pages/utilComponents/avatar2.vue";
import Summery from "@/view/pages/medical_file/comp/summery.vue";

export default {
  name: "AppointmentPrevious",
  components: { Avatar, Summery },
  data() {
    return {
      search: "",
      error: "",
      loadingStatus: true,
      isloadingsum: false,
      opensummery: false,
      opennosummery: false,
      via: null,
      headers: [
        {
          text: this.$t("APPOINTMENTS.APP.doctor"),
          align: "left",
          sortable: true,
          value: "doctor.doctor_id.first_name",
        },
        { text: this.$t("APPOINTMENTS.APP.status"), value: "status" },
        { text: this.$t("APPOINTMENTS.APP.date"), value: "date" },
        { text: this.$t("APPOINTMENTS.APP.time"), value: "time" },
        { text: this.$t("APPOINTMENTS.APP.type"), value: "type" },
        {
          text: this.$t("APPOINTMENTS.APP.action"),
          value: "action",
          align: "right",
        },
      ],
      apps: [],
    };
  },
  mounted() {
    this.getAppointments();
  },
  methods: {
    getTimeZone() {
      return localStorage.timezone;
    },
    async getsummery(id) {
      this.isloadingsum = true;
      await this.$api
        .get("items", "md_doctor_actions?fields=id&filter[appointment]=" + id)
        .then(({ data }) => {
          if (data.data.length > 0) {
            this.via = data.data[0].id;
            this.opensummery = true;
          } else {
            this.opennosummery = true;
          }
          this.isloadingsum = false;
        })
        .catch((error) => {
          this.error = error;
        });
    },
    async getAppointments() {
      this.$moment.locale("en");
      var userInfo = JSON.parse(localStorage.userInfo).id;
      await ApiService.get(
        "items",
        "appointments?fields=id,status,video_room_id,rating,type,date,time,date_time,medical_file," +
          "doctor.doctor_id.first_name,doctor.doctor_id.last_name,doctor.doctor_id.avatar.data,doctor.doctor_id.id," +
          "rating&filter[medical_file.owner]=" +
          userInfo +
          "&filter[date_time][lt]=now&&sort=-date_time&filter[status][neq]=progress"
      )
        .then(({ data }) => {
          //console.log(data);
          this.apps = data.data;
          this.loadingStatus = false;
          this.$moment.locale(localStorage.language);
        })
        .catch((error) => {
          this.error = error;
        });
    },
  },
};
</script>
